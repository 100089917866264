import React from "react";
import ImageTextCard from "../../../components/Cards/ImageTextCard";

export default () => {
  const customerTestimonies = [
    {
      img: "https://upload.wikimedia.org/wikipedia/commons/3/3f/MTN-logo.jpg",
      secondaryContent: (
        <>
          <p className="cardTitle">Mtn Rwanda</p>
          <p className="cardSubtitle">
            Lift brand engagement and products promotion among Rwandan citizens
            by
          </p>
          <p className="cardTitle">67.5%</p>
          <p className="cardSubtitle"> Increased Web Traffic by</p>
          <p className="cardTitle">12,000+ daily visits</p>
        </>
      ),
    },
  ];

  return (
    <div className="testimoniesRoot">
      <p className="title">Don’t Just take our word for it! </p>
      <div className="testimonies">
        {customerTestimonies.map((elt) => (
          <ImageTextCard key={elt.img} content={elt} />
        ))}
      </div>
      <div className="convincing">
        <p className="title">
          Ready to drive towards your marketing goal with NONEHO?
        </p>
        <div className="btnHolder">
          <button className="button">Start Now</button>
        </div>
      </div>
    </div>
  );
};
