import React, { Component } from "react";
import AppLayout from "../../layouts/AppLayout";
import BlogsContainer from "../containers/blogs/BlogsContainer";
import BlogContainer from "../containers/blogs/BlogContainer";

export class Blogs extends Component {
  render() {
    const blogId = this.props.match.params.blogId;

    return blogId ? (
      <AppLayout>
        <BlogContainer blogId={blogId} />
      </AppLayout>
    ) : (
      <AppLayout>
        <BlogsContainer />
      </AppLayout>
    );
  }
}

export default Blogs;
