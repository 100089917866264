import React, { Component } from 'react';
import AppLayout from '../../layouts/AppLayout';
import LandingPageContainer from '../containers/landingPage/LandingPageContainer';

export class LandingPage extends Component {
  render() {
    return (
      <AppLayout>
        <LandingPageContainer />
      </AppLayout>
    );
  }
}

export default LandingPage;
