import React, { Component } from "react";
import chart from "../../../assets/images/chart.svg";
import BudgetCard from "../../../components/Cards/BudgetCard";

export default () => {
  const content = {
    title: "Monthly Budget Cap",
    subtitle: "Adjust anytime",
    color: "#FF9800",
  };

  return (
    <div className="p-MainContent">
      <div className="row-space-around" id="p-MainContent1">
        <p className="not-centered-title">
          Real time visualization of the of your performance
        </p>
        <div>
          <img src={chart} alt="chart ads" />
        </div>
      </div>

      <div className="row-space-around" id="p-MainContent2">
        <div className="ad-content1">
          <BudgetCard content={content} />
        </div>
        <div className="ad-content2">
          <p className="not-centered-title">
            Set a budget that works for your business
          </p>
          <p className="not-centered-subtitle">
            Google ads give you control over your advertising budget. Set a
            monthly budget cap, and never go over it. Plus, you can pause or
            adjust your spend anytime.
          </p>
        </div>
      </div>

      <div className="p-MainContent3">
        <p className="title">Partner with Noneho to find the right customers</p>
        <div>
          <img
            src="https://pbs.twimg.com/profile_images/1120876686848008193/zcMv6ghc.jpg"
            alt="Logo"
          />
        </div>
        <div>
          <p className="subtitle">
            With Google Ads you can reach more relevant customers within your
            budget. Plus, our smart technology will help you improve your ads
            over time to get more of the results that matter to your business.
          </p>
        </div>
      </div>
    </div>
  );
};
