import React, { Component } from "react";
import AppLayout from "../../layouts/AppLayout";
import AdvertisersContainer from "../containers/advertisers/advertisersContainer";

export class Advertisers extends Component {
  render() {
    return (
      <AppLayout>
        <AdvertisersContainer />
      </AppLayout>
    );
  }
}

export default Advertisers;
