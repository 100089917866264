import React from "react";
import "../../../assets/styles/components/introduction.css";

export default (props) => {
  const { content, isTop } = props;
  return (
    <div className="imgIntroductionRoot">
      <div className="imgIntroduction">{content.insideContent}</div>
      <div
        style={{
          backgroundImage: `url(${content.backgroundImage})`,
          backgroundPosition: isTop ? "center" : "bottom",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "65vh",
          filter: 0.5,
          color: "#fff",
          filter: "brightness(0.5)",
        }}
      ></div>
    </div>
  );
};
