import React, { Component } from "react";
import ImageContent from "../../components/landingPage/ImageContent";
import MainContent from "../../components/landingPage/mainContent";
import Testimonies from "../../components/landingPage/testimonies";

import "../../../assets/styles/base.css";
import "../../../assets/styles/views/containers/landingPage.css";

export default () => {
  return (
    <div>
      <ImageContent />
      <MainContent />
      <Testimonies />
    </div>
  );
};
