import React from "react";
import "../../assets/styles/components/cards.css";

export default (props) => {
  const { isListElt, content } = props;

  return (
    <div className={isListElt ? "cardRoot2" : "cardRoot"}>
      <div
        style={{
          backgroundImage: `url(${content.img})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: content.height || "45vh",
        }}
        id={isListElt ? "cardImg-2" : "cardImg"}
      ></div>

      <div className={isListElt ? "cart-elt-2-colored" : "cart-elt-2"}>
        {content.secondaryContent}
      </div>
    </div>
  );
};
