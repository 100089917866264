import React, { Component } from "react";
import AppLayout from "../../layouts/AppLayout";
import TermsAndConditionsContainer from "../containers/nonehoConditions/TermsConditionsContainer";

export class TermsAndConditions extends Component {
  render() {
    return (
      <AppLayout>
        <TermsAndConditionsContainer />
      </AppLayout>
    );
  }
}

export default TermsAndConditions;
