import React, { useState } from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import TextInput from "../../../components/TextInput/TextInput";

export default (props) => {
  const [state, setState] = useState({
    name: "",
    message: "",
    budget: 50,
  });
  const handleInputChange = (event) =>
    setState({ ...state, [event.target.id]: event.target.value });

  const handleRangeChange = (event) =>
    setState({ ...state, budget: event.target.value });

  const onSubmit = () => console.log(state);

  return (
    <div className="formRoot">
      <TextInput
        value={state.name}
        onInputChange={handleInputChange}
        label="Name"
      />
      <TextInput
        isTextArea
        value={state.message}
        onInputChange={handleInputChange}
        label="Message"
      />
      <div style={{ padding: "0 10px" }}>
        <p className="not-centered-smallSubtitle">Budget: ${state.budget}</p>

        <Slider
          aria-label="Budget"
          defaultValue={50}
          valueLabelDisplay="auto"
          onChange={handleRangeChange}
          size="small"
          style={{
            color: "#e7eaed",
          }}
        />
      </div>
      <div style={{ textAlign: "center" }}>
        <button className="button" id="show_in_mobile" onClick={onSubmit}>
          Send Message
        </button>
      </div>
    </div>
  );
};
