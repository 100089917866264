import React, { Component } from "react";
import TermsAndConditions from "../../components/TermsConditionComponent";
import TC_Text from "./TC_Text";

import "../../../assets/styles/base.css";
import "../../../assets/styles/views/containers/influencers.css";

export default () => {
  const content = {
    content1: "Terms and Conditions",
    content2: <TC_Text />,
  };

  return <TermsAndConditions content={content} />;
};
