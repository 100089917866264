import React, { Component } from "react";
import AppLayout from "../../layouts/AppLayout";
import PrivacyPolicyContainer from "../containers/nonehoConditions/PrivacyPolicyContainer";

export class PrivacyPolicy extends Component {
  render() {
    return (
      <AppLayout>
        <PrivacyPolicyContainer />
      </AppLayout>
    );
  }
}

export default PrivacyPolicy;
