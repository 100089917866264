import React from "react";
import FooterElement from "../../components/Footer/footerElement";
import ContactForm from "./Form/contactForm";
import "../../assets/styles/base.css";
import "../../assets/styles/components/footer.css";
import { useHistory } from "react-router";

export default () => {
  const history = useHistory();

  const footerContent = {
    goals: [
      "Monetize Your Creative Work with Us",
      "Understand Your Media Performance",
      "Advertise Through Us",
    ],
    platforms: ["INYARWANDA News", "INYARWANDA Music", "INYARWANDA Tv"],
  };
  return (
    <div
      style={{
        backgroundColor: window.location.pathname.includes("publishers")
          ? "#613A01"
          : "#092f53",
      }}
      id="footerRoot"
    >
      <div className="row-space-between">
        <div className="goals">
          <p className="not-centered-subtitle">Noneho for Business</p>
          <div>
            {footerContent.goals.map((goal) => (
              <FooterElement key={goal} content={goal} />
            ))}
          </div>
        </div>
        <div className="platforms">
          <p className="not-centered-subtitle">Platforms</p>
          <div>
            {footerContent.platforms.map((platform) => (
              <FooterElement key={platform} content={platform} />
            ))}
          </div>
        </div>
        <div className="goals">
          <p className="not-centered-subtitle">Contact us</p>
          <div>
            <ContactForm />
          </div>
        </div>
      </div>

      <p className="footerText">
        Copyright NONEHO 2021. Read our
        <i
          className="terms-privacy"
          onClick={() => history.push("/noneho-privacy-policy")}
        >
          Privacy Policy
        </i>
        or
        <i
          className="terms-privacy"
          onClick={() => history.push("/noneho-terms-conditions")}
        >
          Terms & Conditions
        </i>
      </p>
    </div>
  );
};
