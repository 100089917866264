import React from "react";
import "../../../assets/styles/components/introduction.css";

export default (props) => {
  const { content } = props;

  return (
    <div
      style={{
        backgroundColor: content.color,
      }}
      className="row-space-around"
      id="introduction"
    >
      <div className="intContent1">{content.content1}</div>
      <div className="intContent2">{content.content2}</div>
    </div>
  );
};
