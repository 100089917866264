import React, { Component } from "react";
import AppLayout from "../../layouts/AppLayout";
import InfluencersContainer from "../containers/influencers/InfluencersContainer";

export class Influencers extends Component {
  render() {
    return (
      <AppLayout>
        <InfluencersContainer />
      </AppLayout>
    );
  }
}

export default Influencers;
