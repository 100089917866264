import React from "react";

export default (props) => {
  return (
    <div className="footerElt">
      <span className="footerSubtitle">{">"}</span>
      <span className="footerSubtitle">{props.content}</span>
    </div>
  );
};
