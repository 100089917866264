import React, { Component } from "react";
import AppLayout from "../../layouts/AppLayout";
import PublishersContainer from "../containers/publishers/publishersContainer";

export class Publishers extends Component {
  render() {
    return (
      <AppLayout>
        <PublishersContainer />
      </AppLayout>
    );
  }
}

export default Publishers;
