import React, { Component } from "react";
import AppLayout from "../../layouts/AppLayout";
import AdsAgenciesContainer from "../containers/adsAgencies/adsAgenciesContainer";

export class AdsAgencies extends Component {
  render() {
    return (
      <AppLayout>
        <AdsAgenciesContainer />
      </AppLayout>
    );
  }
}

export default AdsAgencies;
