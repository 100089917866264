import React from "react";
import Footer from "../views/components/FooterComponent";
import Navbar from "../components/Navbar";
import "../assets/styles/layouts/layout.css";

const SimpleLayout = (props) => {
  return (
    <div className="root">
      <div className="navbar">
        <Navbar />
      </div>

      <div className="layout-mainPanel">
        <div className="layout-mainContent">{props.children}</div>
      </div>
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
};

export default SimpleLayout;
