import React from "react";
import IntroductionComponent from "../introductions/IntroductionComponent";

import customerCareImg from "../../../assets/images/customer-care.png";
import { openLinkInNewTab } from "../../../helpers/helperMethods";

export default () => {
  const content = {
    color: "#a2c8ec",
    content1: (
      <>
        <p className="title">Reach To your New Potential Clients</p>
        <button className="intro-button" onClick={() => openLinkInNewTab()}>
          Create Ad
        </button>
      </>
    ),
    content2: (
      <>
        <img src={`${customerCareImg}`} alt="customer care" />
      </>
    ),
  };
  return <IntroductionComponent content={content} />;
};
