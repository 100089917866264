import React from "react";
import img from "../../../assets/images/creators.png";
import { navigateToPage } from "../../../helpers/helperMethods";
import ImageTextCard from "../../../components/Cards/ImageTextCard";

import { useHistory } from "react-router";

export default () => {
  const history = useHistory();

  const blogs = [
    {
      img: "https://source.unsplash.com/random",
      id: 1,
      secondaryContent: (
        <>
          <p className="cardTitle">Blog Title</p>
          <p className="cardSubtitle">Some explanation....</p>
        </>
      ),
      height: "20vh",
    },
    {
      img: "https://source.unsplash.com/random",
      id: 2,
      secondaryContent: (
        <>
          <p className="cardTitle">Blog Title</p>
          <p className="cardSubtitle">
            Occaecat non reprehenderit laboris magna voluptate. Do consectetur
            aliqua quis proident nisi laboris. Dolor duis eiusmod fugiat nisi
            esse velit sit reprehenderit officia anim sunt excepteur Lorem eu.
            Eu magna aliqua excepteur Lorem excepteur non ullamco enim culpa
            irure aute. Aliqua commodo ex amet cillum minim aliquip magna.
            Nostrud aliqua occaecat elit est veniam culpa nulla pariatur dolore.
          </p>
        </>
      ),
      height: "20vh",
    },
    {
      img: "https://source.unsplash.com/random",
      id: 3,
      secondaryContent: (
        <>
          <p className="cardTitle">Blog Title</p>
          <p className="cardSubtitle">
            Ullamco aute dolor dolore eiusmod aliqua. Et excepteur laboris quis
            laborum amet aliquip irure officia amet cupidatat aute proident
            excepteur do. Quis id culpa incididunt voluptate aliquip proident
            est voluptate labore esse quis. Mollit pariatur consectetur irure
            consectetur aliqua quis aute nulla non laboris velit dolore.
          </p>
        </>
      ),
      height: "20vh",
    },
  ];

  return (
    <div>
      <p className="title"> All Blogs</p>
      <div>
        {blogs.map((blogElt) => (
          <div
            key={blogElt.id}
            onClick={() => navigateToPage(`/blogs/${blogElt.id}`, history)}
          >
            <ImageTextCard isListElt content={blogElt} />
          </div>
        ))}
      </div>
    </div>
  );
};
