import React from "react";
import "../../assets/styles/components/cards.css";
import ProgressBar from "@ramonak/react-progress-bar";

export default (props) => {
  const { content } = props;

  return (
    <div className="b-cardRoot">
      <div className="b-cart-elt-2">
        <p className="b-cardTitle">{content.title}</p>
        <p className="b-cardSubtitle">{content.subtitle}</p>
        <div>
          <div
            className="row-space-between"
            style={{
              color: content.color ? content.color : "#1876d1",
              padding: "25px 0px 5px 0px",
            }}
          >
            <p>$$</p>
            <p>$$$</p>
          </div>
          <div>
            <ProgressBar
              completed={50}
              bgColor={content.color ? content.color : "#1876d1"}
              height="10px"
              borderRadius="3px"
              isLabelVisible={false}
              baseBgColor="#E8EAED"
              labelColor="#e80909"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
