import React from "react";
import img from "../../../assets/images/creators.png";
import { openLinkInNewTab } from "../../../helpers/helperMethods";
import Introduction from "../introductions/ImageIntroduction";

export default () => {
  const content = {
    insideContent: (
      <>
        <p className="imgIntroductionTitle">
          PROMOTE & MONETIZE YOUR CONTENT WITH INYARWANDA
        </p>
        <button className="button" onClick={() => openLinkInNewTab()}>
          Start Now
        </button>
      </>
    ),
    backgroundImage: img,
  };

  return <Introduction isTop content={content} />;
};
