import React, { Component } from "react";
import TermsAndConditions from "../../components/TermsConditionComponent";
import PP_Text from "./PP_Text";

import "../../../assets/styles/base.css";
import "../../../assets/styles/views/containers/influencers.css";

export default () => {
  const content = {
    content1: "Privacy Policy for Noneho",
    content2: <PP_Text />,
  };

  return <TermsAndConditions content={content} />;
};
