import React, { Component } from "react";
import ImageContent from "../../components/influencers/ImageContent";
import MainContent from "../../components/influencers/mainContent";

import "../../../assets/styles/base.css";
import "../../../assets/styles/views/containers/influencers.css";

export default () => {
  return (
    <div>
      <ImageContent />
      <MainContent />
    </div>
  );
};
