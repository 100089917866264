import React, { Component } from "react";
import BlogComponent from "../../components/blogs/BlogComponent";

import "../../../assets/styles/base.css";
import "../../../assets/styles/views/containers/blogs.css";

export default () => {
  return (
    <div>
      <BlogComponent />
    </div>
  );
};
