import React from "react";
import { openLinkInNewTab } from "../../../helpers/helperMethods";
import IntroductionComponent from "../introductions/IntroductionComponent";

export default () => {
  const content = {
    color: "#a2c8ec",
    content1: (
      <>
        <img
          src="https://img.freepik.com/free-vector/bloggers-advertising-referrals-young-people-with-gadgets-loudspeakers-announcing-news-attracting-target-audience-vector-illustration-marketing-promotion-communication_74855-8244.jpg?size=626&ext=jpg"
          alt="customer care"
        />
      </>
    ),
    content2: (
      <>
        <p className="title">
          Let Us help you to with your Ads and Ads Management
        </p>
        <button className="intro-button" onClick={() => openLinkInNewTab()}>
          Create Ad
        </button>
      </>
    ),
  };
  return <IntroductionComponent content={content} />;
};
