import React from "react";
import { openLinkInNewTab } from "../../../helpers/helperMethods";
import IntroductionComponent from "../introductions/IntroductionComponent";

export default () => {
  const content = {
    color: "#FFAC32",
    content1: (
      <>
        <img
          src="https://images.unsplash.com/photo-1515165616480-efd71925068f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2340&q=80"
          alt="customer care"
        />
      </>
    ),
    content2: (
      <>
        <p className="title">Manage you Pushments and Content</p>
        <button
          className="intro-button"
          id="publishers"
          onClick={() => openLinkInNewTab("https://supply.noneho.com/")}
        >
          Get Started
        </button>
      </>
    ),
  };
  return <IntroductionComponent content={content} />;
};
