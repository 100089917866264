import {
  FETCH_DATA,
} from "./types";

const initialState = {
  loading: false,
  data: {},
};

export default class landingPagesReducers {
  static landingPageReducer(state = initialState, action) {
    switch (action.type) {
      case `${FETCH_DATA}_PENDING`:
        return {
          ...state,
          loading: true,
        };
      case `${FETCH_DATA}_FULFILLED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case `${FETCH_DATA}_REJECTED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      default:
        return state;
    }
  }
}
