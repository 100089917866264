import React, { Component } from "react";
import Introduction from "../../components/publishers/Introduction";
import MainContent from "../../components/publishers/mainContent";

import "../../../assets/styles/base.css";
import "../../../assets/styles/views/containers/publishers.css";

export default () => {
  return (
    <div>
      <Introduction />
      <MainContent />
    </div>
  );
};
