import React from "react";
import "../../assets/styles/components/textInput.css";

export default (props) => {
  const { id, value, onInputChange, label, placeholder, isTextArea } = props;

  const onChange = (event) => onInputChange(event);

  return (
    <div className="textInputRoot">
      {/* <div className="textInput">
        <label className="label">
          {label}
          <br /> */}
      <span className="input">
        {isTextArea ? (
          <textarea
            id={id || label.toLowerCase()}
            value={value}
            onChange={onChange}
            className="inputTextarea"
            placeholder={placeholder || `Enter ${id || label.toLowerCase()}...`}
          />
        ) : (
          <input
            className="inputField"
            type="text"
            value={value}
            placeholder={placeholder || `Enter ${id || label.toLowerCase()}...`}
            onChange={onChange}
            id={id || label.toLowerCase()}
          />
        )}
      </span>
      {/* </label> */}
      {/* </div> */}
    </div>
  );
};
