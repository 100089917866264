import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import AdsAgencies from "../views/pages/AdsAgencies";
import Advertisers from "../views/pages/AdvertisersPage";
import Blogs from "../views/pages/Blogs";
import Influencers from "../views/pages/InfluencersPage";
import LandingPage from "../views/pages/LandingPage";
import PrivacyPolicy from "../views/pages/PrivacyPolicy";
import Publishers from "../views/pages/PublishersPage";
import TermsAndConditions from "../views/pages/TermsConditions";

const AppRoute = () => {
  return (
    <BrowserRouter>
      <div className="app">
        <Switch>
          <Route exact path="/" component={LandingPage} />
          <Route exact path="/ads-agencies" component={AdsAgencies} />
          <Route exact path="/advertisers" component={Advertisers} />
          <Route exact path="/publishers" component={Publishers} />
          <Route exact path="/influencers" component={Influencers} />
          <Route exact path="/blogs" component={Blogs} />
          <Route exact path="/blogs/:blogId" component={Blogs} />
          <Route
            exact
            path="/noneho-terms-conditions"
            component={TermsAndConditions}
          />
          <Route
            exact
            path="/noneho-privacy-policy"
            component={PrivacyPolicy}
          />
        </Switch>
      </div>
    </BrowserRouter>
  );
};

export default AppRoute;
