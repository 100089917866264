import React, { Component } from "react";
import featured from "../../../assets/images/feature.png";
import featured_on_music from "../../../assets/images/featuredonmusic.png";
import chart from "../../../assets/images/chart2.png";
import BudgetCard from "../../../components/Cards/BudgetCard";

export default () => {
  const content = {
    title: "Monthly Budget Cap",
    subtitle: "Adjust anytime",
  };

  return (
    <div className="f-MainContent">
      <div className="row-space-around" id="f-MainContent1">
        <div>
          <img src={featured} alt="featured on socials ads" />
        </div>
        <span>
          <p className="not-centered-title">
            Get Shout out and Promote your content on our Social Medias
          </p>
          <p className="not-centered-subtitle">
            Educate people about your products, services, promotions, and all
            the exciting stuff about your business
          </p>
        </span>
      </div>

      <div className="row-space-around" id="f-MainContent">
        <span>
          <p className="not-centered-title">Get Featured on our Platforms</p>
        </span>
        <div>
          <img
            className="feature-img2"
            src={featured_on_music}
            alt="featured on music"
          />
        </div>
      </div>

      <div className="row-space-around" id="f-MainContent">
        <div>
          <img src={chart} alt="chart" id="chart-img" />
        </div>
        <span id="chart-title">
          <p className="not-centered-title">Measure your content performance</p>
        </span>
      </div>

      <div className="row-space-around" id="f-MainContent">
        <div className="ad-content1">
          <BudgetCard content={content} />
        </div>
        <div className="ad-content2">
          <p className="not-centered-title">
            Set a budget that works for your business
          </p>
          <p className="not-centered-subtitle">
            Google ads give you control over your advertising budget. Set a
            monthly budget cap, and never go over it. Plus, you can pause or
            adjust your spend anytime.
          </p>
        </div>
      </div>

      <div className="f-MainContent3">
        <p className="title">Partner with Noneho to find the right customers</p>
        <div>
          <img
            src="https://pbs.twimg.com/profile_images/1120876686848008193/zcMv6ghc.jpg"
            alt="Logo"
          />
        </div>
        <div>
          <p className="subtitle">
            With Google Ads you can reach more relevant customers within your
            budget. Plus, our smart technology will help you improve your ads
            over time to get more of the results that matter to your business.
          </p>
        </div>
      </div>
    </div>
  );
};
