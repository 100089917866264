import React, { useState } from "react";
import BlogsComponent from "../../components/blogs/BlogsComponent";
import Pagination from "react-responsive-pagination";
import "../../../assets/styles/base.css";
import "../../../assets/styles/views/containers/influencers.css";
import "../../../assets/styles/plugins/pagination.css";

export default () => {
  const totalPages = 5;
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    // ... do something with `page`
  };
  return (
    <div>
      <BlogsComponent />
      <Pagination
        total={totalPages}
        current={currentPage}
        onPageChange={(page) => handlePageChange(page)}
        // more info: https://react-responsive-pagination.elantha.com/custom-styled-pagination
      />
    </div>
  );
};
