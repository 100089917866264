import React from "react";
import "../../assets/styles/components/introduction.css";

export default (props) => {
  const { content } = props;

  return (
    <div>
      <div className="title">{content.content1}</div>
      <div>{content.content2}</div>
    </div>
  );
};
