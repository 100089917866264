import React from "react";
import Traffic from "../../../assets/images/traffic.svg";
import Release from "../../../assets/images/release.svg";
import shoppingCart from "../../../assets/images/shopping-cart.svg";
import image2 from "../../../assets/images/image2.png";

export default () => {
  const results = [
    {
      title: "Drive More Web Traffic ",
      subtitle: "Get more more people to visit your website.",
      img: Traffic,
    },
    {
      title: "Promote Products and Services",
      subtitle:
        "Educate people about your products, services, promotions, and all the exciting stuff about your business",
      img: Release,
    },
    {
      title: "Boost your Sales ",
      subtitle: "Get more more people to visit your website.",
      img: shoppingCart,
    },
  ];
  return (
    <div className="mainContent">
      <div className="mainContent1">
        <p className="title">
          We offer advertising solutions for every level of expertise.
        </p>
        <p className="subtitle">
          You don't have to be an expert to start advertising on Facebook.
          Create and run campaigns using simple self-service tools, and track
          their performance with easy-to-read reports. More than two billion
          people use Facebook every month – so no matter what kind of audience
          you want to reach, you'll find them here.
        </p>
      </div>

      <div className="row-space-between" id="mainContent2">
        <p className="title">
          Allow us to add your <br /> Ads to NONEHO
        </p>
        <div>
          <img src={image2} alt="showing ads" />
        </div>
      </div>

      <div className="mainContent3">
        <p className="title">
          We offer advertising solutions for every level of expertise.
        </p>
        <div className="row-space-around">
          {results.map((elt) => (
            <div key={elt.title}>
              <img src={elt.img} className="img" alt={elt.title} />
              <p className="smallTitle">{elt.title}</p>
              <p className="smallSubtitle">{elt.subtitle}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
