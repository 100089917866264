import React, { useEffect, useState } from "react";
import "../assets/styles/components/navbar.css";
import {
  openLinkInNewTab,
  useWindowDimensions,
} from "../helpers/helperMethods";
import { Icon } from "@iconify/react";
import menuAlt02 from "@iconify/icons-ci/menu-alt-02";
import closeFill from "@iconify/icons-eva/close-fill";
import { useHistory } from "react-router";

export default () => {
  const history = useHistory();
  const shouldShowBtn =
    window.location.pathname.includes("advertisers") ||
    window.location.pathname.includes("publishers") ||
    window.location.pathname.includes("influencers") ||
    window.location.pathname.includes("ads-agencies");

  const navbarFor = window.location.pathname.includes("advertisers")
    ? "Advertisers"
    : window.location.pathname.includes("publishers")
    ? "Publishers"
    : window.location.pathname.includes("influencers")
    ? "Influencers"
    : window.location.pathname.includes("ads-agencies")
    ? "Ads-Agencies"
    : "Business";

  const dimension = useWindowDimensions();

  const [state, setState] = useState({
    menuHide: false,
    showMobileMenu: false,
  });

  const mobileMenuHandle = () => {
    setState((prevState) => ({
      ...state,
      showMobileMenu: !prevState.showMobileMenu,
    }));
  };

  const navigateTo = (url) => history.push(url);

  return (
    <div className="navRoot">
      <div className="navContent">
        <span className="navLogo" onClick={() => navigateTo("/")}>
          NONEHO for {navbarFor}
        </span>

        <div className={state.showMobileMenu ? "navLinksActive" : "navLinks"}>
          <span
            className={
              window.location.pathname.includes("advertisers")
                ? "active_content"
                : "content"
            }
            onClick={() => navigateTo("/advertisers")}
          >
            Advertisers
          </span>
          <span
            className={
              window.location.pathname.includes("ads-agencies")
                ? "active_content"
                : "content"
            }
            onClick={() => navigateTo("/ads-agencies")}
          >
            Ads-Agencies
          </span>
          <span
            className={
              window.location.pathname.includes("publishers")
                ? "active_content"
                : "content"
            }
            onClick={() => navigateTo("/publishers")}
          >
            Publishers
          </span>
          <span
            className={
              window.location.pathname.includes("influencers")
                ? "active_content"
                : "content"
            }
            onClick={() => navigateTo("/influencers")}
          >
            Influencers
          </span>
          <span
            className={
              window.location.pathname.includes("blogs")
                ? "active_content"
                : "content"
            }
            onClick={() => navigateTo("/blogs")}
          >
            Blogs
          </span>

          {shouldShowBtn && dimension.width <= 600 && (
            <span className="content">
              <button
                className={state.showMobileMenu ? "showButton" : "button"}
                onClick={() => openLinkInNewTab()}
                id={
                  window.location.pathname.includes("publishers")
                    ? "publishers"
                    : null
                }
              >
                {window.location.pathname.includes("publishers")
                  ? "Get Started"
                  : window.location.pathname.includes("influencers")
                  ? "Start Now"
                  : "Create Ad"}
              </button>
            </span>
          )}
        </div>
      </div>

      <div className="navButton">
        {shouldShowBtn && (
          <button
            className="button"
            onClick={() => openLinkInNewTab()}
            id={
              window.location.pathname.includes("publishers")
                ? "publishers"
                : null
            }
          >
            {window.location.pathname.includes("publishers")
              ? "Get Started"
              : window.location.pathname.includes("influencers")
              ? "Start Now"
              : "Create Ad"}
          </button>
        )}

        <span
          className={dimension.width <= 600 ? "hideHumbuggerMenu" : "hide"}
          onClick={mobileMenuHandle}
        >
          {state.showMobileMenu ? (
            <Icon icon={closeFill} height={32} />
          ) : (
            <Icon icon={menuAlt02} id="humbugger" height={32} />
          )}
        </span>
      </div>
    </div>
  );
};
